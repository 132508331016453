import ApplicationController from 'modules/application_controller';

const INTERCEPT_REJECT_ERROR = 'fuse:intercept-promise-reject';
const EVENT_NAMES = {
  VALIDATE: 'validate',
  BEFORE_SUBMIT: 'before-submit',
  SUBMIT_START: 'submit-start',
  BEFORE_SUBMIT_REQUEST: 'before-submit-request',
  SUBMIT_PREVENTED: 'submit-prevented',
  SUBMIT_END: 'submit-end',
  SUBMIT_END_SUCCESS: 'submit-end-success',
  SUBMIT_END_FAIL: 'submit-end-fail',
};
const FORM_FIELDS_STIMULUS_CONTROLLER_PREFIX = 'fuse--form';

export default class extends ApplicationController {
  static targets = ['errorNotice', 'errorNoticeText', 'errorNoticeFieldLinkTemplate'];

  static values = {
    defaultErrorMessage: {
      type: String,
      default: 'Check the field.',
    },
    defaultFieldLabel: {
      type: String,
      default: 'Field',
    },
  };

  initialize() {
    this.fields = new Map();
    this.errors = new Set();
    this.delayedResultId = null;
  }

  // Form Reset
  reset() {
    this.element.reset();
  }

  // Error Notice Management
  toggleErrorNotice(hidden = false) {
    if (!this.hasErrorNoticeTarget || !this.hasErrorNoticeTextTarget) return;

    if (hidden) {
      this._clearErrorNotice();

      return;
    }

    const errorItems = this._generateErrorItems();

    this._displayErrorNotice(errorItems);
  }

  _clearErrorNotice() {
    this.errorNoticeTextTarget.textContent = '';
    this.errorNoticeTarget.hidden = true;
  }

  _generateErrorItems() {
    return Array.from(this.errors)
      .map((error) => {
        const field = this.fields.get(error);
        if (!field) return null;

        const label = this._getFieldLabel(field, error);
        const message = this._getFieldMessage(field);
        return `<li>${label}: ${message}</li>`;
      })
      .filter(Boolean);
  }

  _getFieldLabel(field, error) {
    let label = field.stimulusController?.labelText || this.defaultFieldLabelValue;

    if (this.hasErrorNoticeFieldLinkTemplateTarget) {
      label = this.errorNoticeFieldLinkTemplateTarget.innerHTML
        .replace('{ID}', this._normalizeErrorId(error))
        .replace('{CONTENT}', label)
        .trim();
    }

    return label;
  }

  _getFieldMessage(field) {
    return field.stimulusController?.validityMessageTarget?.textContent?.trim() || this.defaultErrorMessageValue;
  }

  _normalizeErrorId(error) {
    return error.replace(/[[\]]/g, '_').replace(/_+$/, '').replace(/_+/g, '_');
  }

  _displayErrorNotice(errorItems) {
    const html = errorItems.join('');

    this.errorNoticeTextTarget.innerHTML = html;
    this.errorNoticeTextTarget.hidden = !html;
    this.errorNoticeTarget.hidden = false;
  }

  // Field Navigation
  scrollToErrorField(event) {
    const id = event.target.getAttribute('href').replace('#', '');
    const field = document.getElementById(id);

    if (!field) return;

    event.preventDefault();

    const fieldWrapper = field.closest(`[data-controller^="${FORM_FIELDS_STIMULUS_CONTROLLER_PREFIX}"]`);

    (fieldWrapper || field).scrollIntoView({ behavior: 'instant' });
    field.focus({ preventScroll: true });
  }

  // Validation
  validateAndDispatchFuseValidate(event) {
    if (this._shouldPreventSubmission()) {
      this._preventEventPropagation(event);
      this.toggleErrorNotice(false);
      this.focusFirstFieldWithError();
    } else {
      this.toggleErrorNotice(true);
    }
  }

  _shouldPreventSubmission() {
    return !this.validate()
      || this.errors.size > 0
      || !this._dispatchFuseEvents(EVENT_NAMES.VALIDATE, { cancelable: true });
  }

  _preventEventPropagation(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  // Form Submission Events
  dispatchFuseBeforeSubmit(event) {
    if (!this._dispatchFuseEvents(EVENT_NAMES.BEFORE_SUBMIT, { cancelable: true })) {
      this._preventEventPropagation(event);
    }
  }

  dispatchFuseSubmitStart(event) {
    this._dispatchFuseEvents(EVENT_NAMES.SUBMIT_START, { detail: event.detail });
  }

  async dispatchFuseBeforeSubmitRequest(event) {
    event.preventDefault();

    try {
      await this._dispatchFuseEventsWithInterception(EVENT_NAMES.BEFORE_SUBMIT_REQUEST, { detail: event.detail });
    } catch (err) {
      if (err.message === INTERCEPT_REJECT_ERROR) {
        this._dispatchFuseEvents(EVENT_NAMES.SUBMIT_PREVENTED);
        return;
      }

      throw err;
    }

    if (event.detail && event.detail.resume) {
      event.detail.resume();
    }
  }

  // Delayed Result Handling
  dispatchFuseSubmitEndOrWaitForDelayedResult(event) {
    const { detail: { success = false, fetchResponse = null } } = event;

    if (success && fetchResponse) {
      const delayedResultId = fetchResponse.response.headers.get('X-SlidesLive-DelayedResultId');

      if (delayedResultId) {
        this.delayedResultId = delayedResultId;
        return;
      }
    }

    this.dispatchFuseSubmitEnd({ success });
  }

  dispatchFuseSubmitEndFromDelayedResult(event) {
    const { detail: { delayedResultId = null, success = true } } = event;

    if (!delayedResultId || delayedResultId !== this.delayedResultId) return;

    this.delayedResultId = null;
    this.dispatchFuseSubmitEnd({ success });
  }

  dispatchFuseSubmitEnd(detail) {
    this._dispatchFuseEvents(EVENT_NAMES.SUBMIT_END, { detail });
    this._dispatchFuseEvents(
      detail.success ? EVENT_NAMES.SUBMIT_END_SUCCESS : EVENT_NAMES.SUBMIT_END_FAIL,
      { detail },
    );
  }

  // Field Management
  validate() {
    let isValid = true;

    for (const field of this.fields.values()) {
      if (field && typeof field.closest === 'function' && field.closest('[hidden]')) continue;

      if (!field.stimulusController.validate(true)) {
        isValid = false;
      }
    }

    return isValid;
  }

  focusFirstFieldWithError() {
    const firstError = Array.from(this.errors)[0];
    const firstErrorField = this.fields.get(firstError);

    if (!firstErrorField?.stimulusController?.hasInputTarget) return;

    const inputTarget = this._getFocusableInput(firstErrorField);

    if (inputTarget) inputTarget.focus();
  }

  _getFocusableInput(field) {
    const { inputTarget, element } = field.stimulusController;

    return inputTarget.tabIndex !== -1
      ? inputTarget
      : element.querySelector('input:not([tabindex="-1"]), textarea:not([tabindex="-1"])');
  }

  // Field Event Handlers
  fieldAdded({ detail }) {
    this.fields.set(detail.name, detail);
  }

  fieldValidityChanged({ detail: { name, isValid } }) {
    if (isValid) {
      this.errors.delete(name);
    } else {
      this.errors.add(name);
    }
  }

  fieldRemoved({ detail: { name } }) {
    this.errors.delete(name);
    this.fields.delete(name);
  }

  // Event Dispatch Utilities
  _dispatchFuseEvents(eventName, { detail = {}, cancelable = false } = {}) {
    const elementEvent = this._dispatchFuseEvent(eventName, { target: this.element, detail, cancelable });
    const windowEvent = this._dispatchFuseEvent(eventName, { target: window, detail, cancelable });

    return !elementEvent.defaultPrevented && !windowEvent.defaultPrevented;
  }

  async _dispatchFuseEventsWithInterception(eventName, { detail = {} } = {}) {
    const elementEvent = await this._dispatchFuseEventWithInterception(eventName, { target: this.element, detail });
    const windowEvent = await this._dispatchFuseEventWithInterception(eventName, { target: window, detail });

    return [elementEvent, windowEvent];
  }

  _dispatchFuseEvent(eventName, { target = this.element, detail = {}, cancelable = false } = {}) {
    return this.dispatch(eventName, {
      target,
      prefix: 'fuse',
      bubbles: false,
      cancelable,
      detail: { ...detail, target: this.element },
    });
  }

  async _dispatchFuseEventWithInterception(eventName, { target = this.element, detail = {} } = {}) {
    const interceptionPromises = [];
    const intercept = this._createInterceptor(interceptionPromises);

    const event = this._dispatchFuseEvent(eventName, {
      target,
      detail: { ...detail, intercept },
    });

    await Promise.all(interceptionPromises);

    return event;
  }

  _createInterceptor(promiseArray) {
    return () => {
      let resolve;
      let reject;

      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = () => rej(new Error(INTERCEPT_REJECT_ERROR));
      });

      promiseArray.push(promise);

      return { resume: resolve, cancel: reject };
    };
  }
}
