import formatRelativeTime from 'plugins/time/format_relative_time';

class RelativeTime {
  constructor(
    date,
    { locale = 'en', precise = false, alwaysRelative = false, limitAhead = true, limitElapsed = true } = {},
  ) {
    this.date = date;
    this.locale = locale;
    this.precise = precise;
    this.alwaysRelative = alwaysRelative;
    this.limitAhead = limitAhead;
    this.limitElapsed = limitElapsed;
  }

  toString() {
    const ago = this.timeElapsed();

    if (ago) {
      return ago;
    }

    const ahead = this.timeAhead();

    if (ahead) {
      return ahead;
    }

    return this.formatDate();
  }

  timeElapsed() {
    const ms = this.now.getTime() - this.date.getTime();
    const sec = Math.round(ms / 1000);
    const min = Math.round(sec / 60);
    const hr = Math.round(min / 60);
    const day = Math.round(hr / 24);

    if (ms >= 0 && (!this.limitElapsed || day < 30)) {
      if (this.alwaysRelative) {
        return this.timeAgoFromMs(ms);
      }
    }

    return null;
  }

  timeAhead() {
    const ms = this.date.getTime() - this.now.getTime();
    const sec = Math.round(ms / 1000);
    const min = Math.round(sec / 60);
    const hr = Math.round(min / 60);
    const day = Math.round(hr / 24);

    if (ms >= 0 && (!this.limitAhead || day < 30)) {
      if (this.alwaysRelative) {
        return this.timeUntil();
      }
    }

    return null;
  }

  timeAgo() {
    const ms = this.now.getTime() - this.date.getTime();

    return this.timeAgoFromMs(ms);
  }

  timeAgoFromMs(ms) {
    const sec = Math.round(ms / 1000);
    const min = Math.round(sec / 60);
    const hr = Math.round(min / 60);
    const day = Math.round(hr / 24);
    const month = Math.round(day / 30);
    const year = Math.round(month / 12);

    let value;
    let unit;

    if (sec < 60) {
      if (this.precise) {
        value = -sec;
        unit = 'second';
      } else {
        value = 0;
        unit = 'minute';
      }
    } else if (min < 45) {
      value = -min;
      unit = 'minute';
    } else if (min < 90) {
      value = -hr;
      unit = 'hour';
    } else if (hr < 24) {
      value = -hr;
      unit = 'hour';
    } else if (hr < 36) {
      value = -day;
      unit = 'day';
    } else if (day < 30) {
      value = -day;
      unit = 'day';
    } else if (month < 18) {
      value = -month;
      unit = 'month';
    } else {
      value = -year;
      unit = 'year';
    }

    return formatRelativeTime(value, { locale: this.locale, unit, absolute: true, datetime: this.date });
  }

  microTimeAgo() {
    const ms = this.now.getTime() - this.date.getTime();
    const sec = Math.round(ms / 1000);
    const min = Math.round(sec / 60);
    const hr = Math.round(min / 60);
    const day = Math.round(hr / 24);
    const month = Math.round(day / 30);
    const year = Math.round(month / 12);

    if (min < 1) {
      return '1m';
    }

    if (min < 60) {
      return `${min}m`;
    }

    if (hr < 24) {
      return `${hr}h`;
    }

    if (day < 365) {
      return `${day}d`;
    }

    return `${year}y`;
  }

  timeUntil() {
    const ms = this.date.getTime() - this.now.getTime();

    return this.timeUntilFromMs(ms);
  }

  timeUntilFromMs(ms) {
    const sec = Math.round(ms / 1000);
    const min = Math.round(sec / 60);
    const hr = Math.round(min / 60);
    const day = Math.round(hr / 24);
    const month = Math.round(day / 30);
    const year = Math.round(month / 12);

    let value;
    let unit;

    if (month >= 18) {
      value = year;
      unit = 'year';
    } else if (month >= 12) {
      value = year;
      unit = 'year';
    } else if (day >= 45) {
      value = month;
      unit = 'month';
    } else if (day >= 30) {
      value = month;
      unit = 'month';
    } else if (hr >= 36) {
      value = day;
      unit = 'day';
    } else if (hr >= 24) {
      value = day;
      unit = 'day';
    } else if (min >= 90) {
      value = hr;
      unit = 'hour';
    } else if (min >= 45) {
      value = hr;
      unit = 'hour';
    } else if (sec >= 60) {
      value = min;
      unit = 'minute';
    } else {
      value = 0;
      unit = 'minute';
    }

    return formatRelativeTime(value, { locale: this.locale, unit, absolute: true });
  }

  microTimeUntil() {
    const ms = this.date.getTime() - this.now.getTime();
    const sec = Math.round(ms / 1000);
    const min = Math.round(sec / 60);
    const hr = Math.round(min / 60);
    const day = Math.round(hr / 24);
    const month = Math.round(day / 30);
    const year = Math.round(month / 12);

    if (day >= 365) {
      return `${year}y`;
    }

    if (hr >= 24) {
      return `${day}d`;
    }

    if (min >= 60) {
      return `${hr}h`;
    }

    if (min > 1) {
      return `${min}m`;
    }

    return '1m';
  }

  formatDate() {
    const format = {
      day: 'numeric',
      month: 'numeric',
    };

    if (this.now.getUTCFullYear() !== this.date.getUTCFullYear()) {
      format.year = 'numeric';
    }

    const dtf = new Intl.DateTimeFormat(this.locale, format);

    return dtf.format(this.date);
  }

  get now() {
    return new Date();
  }
}

export default RelativeTime;
