import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  connect() {
    this.csrfParam = document.querySelector('meta[name=csrf-param]')?.getAttribute('content');
    this.csrfToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content');

    this.baseClickThroughData = this.parseClickThroughData(this.element.dataset.clickThrough);
  }

  send(event) {
    const target = this.findClickThroughTarget(event);

    if (!target) return;

    const formData = this.prepareFormData(target);

    this.sendTracking(formData);
  }

  findClickThroughTarget(event) {
    return event.target.closest('[data-click-through]');
  }

  prepareFormData(target) {
    const formData = new FormData();
    const clickThroughData = this.createClickThroughDataForEventTarget(target);

    Object.entries(clickThroughData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (this.csrfParam && this.csrfToken) {
      formData.append(this.csrfParam, this.csrfToken);
    }

    return formData;
  }

  createClickThroughDataForEventTarget(target) {
    const targetData = this.parseClickThroughData(target.dataset.clickThrough);

    return {
      ...this.dataValue,
      ...targetData,
    };
  }

  parseClickThroughData(dataString) {
    if (!dataString) return {};

    try {
      return JSON.parse(dataString);
    } catch (error) {
      console.warn('Invalid click-through data format:', error);
      return {};
    }
  }

  sendTracking(formData) {
    if (!this.clickThroughUrl) {
      console.warn('No tracking URL specified');
      return;
    }

    navigator.sendBeacon(this.clickThroughUrl, formData);
  }

  get clickThroughUrl() {
    return this.element.dataset.clickThroughUrl;
  }
}
