import ApplicationController from 'modules/application_controller';
import Sortable from 'sortablejs';

import defer from 'plugins/utilities/defer';

export default class extends ApplicationController {
  static targets = ['list', 'orderId'];

  static values = {
    disableAnimationItemsThreshold: { type: Number, default: 20 },
    animationDuration: { type: Number, default: 150 },
  };

  initialize() {
    this.sortable = null;
  }

  connect() {
    if (this.isTurboPreview) return;

    defer(this.initSortable.bind(this));
  }

  disconnect() {
    this.destroySortable();
  }

  initSortable() {
    const shouldDisableAnimation = this.shouldDisableAnimation();

    this.sortable = new Sortable(this.listTarget, {
      animation: shouldDisableAnimation ? 0 : this.animationDurationValue,
      sort: true,
      handle: '[data-sortable-handle]',
      draggable: '[data-sortable-item]',
      onSort: this.updateOrderIds.bind(this),
    });

    this.updateOrderIds();
  }

  updateOrderIds() {
    if (!this.hasOrderIdTarget) return;

    this.orderIdTargets.forEach((target, index) => {
      target.value = index;
    });
  }

  destroySortable() {
    if (!this.sortable) return;

    this.sortable.destroy();
    this.sortable = null;
  }

  shouldDisableAnimation() {
    return this.listTarget.children.length > this.disableAnimationItemsThresholdValue;
  }

  isWebKit() {
    return navigator.userAgent.includes('AppleWebKit');
  }
}
