import ApplicationController from 'modules/application_controller';
import stimulus, { FUSE_TOOLTIP_CONTROLLER } from 'plugins/stimulus';

export default class extends ApplicationController {
  static values = {
    locale: {
      type: String,
      default: null,
    },
    datetime: {
      type: String,
      default: null,
    },
    titleFormat: {
      type: Object,
      default: {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
      },
    },
  };

  initialize() {
    this.parsedDatetime = null;
  }

  connect() {
    this.parseDatetimeValue();
    this.setTitle();
  }

  localeValueChanged() {
    this.setTitle();
  }

  datetimeValueChanged() {
    this.parseDatetimeValue();
    this.setTitle();
  }

  titleFormatValueChanged() {
    this.setTitle();
  }

  parseDatetimeValue() {
    this.parsedDatetime = this.datetimeValue ? new Date(this.datetimeValue) : null;
  }

  setTitle() {
    if (!stimulus.hasTarget(this.element, { [FUSE_TOOLTIP_CONTROLLER]: 'item' })) return;

    const title = this.formattedTitle;

    this.element.setAttribute('title', title);
  }

  get formattedTitle() {
    if (!this.parsedDatetime) return '';

    const dtf = new Intl.DateTimeFormat(this.localeValue, this.titleFormatValue);

    return dtf.format(this.parsedDatetime);
  }
}
