import ApplicationController from 'modules/application_controller';

import hide from 'plugins/element/hide';
import show from 'plugins/element/show';
import fire from 'plugins/utilities/fire';

export default class extends ApplicationController {
  static values = {
    name: { type: String, required: true },
    hide: { type: Boolean, default: false },
  };

  initialize() {
    this.scopeCache = null;
  }

  connect() {
    if (this.isTurboPreview) return;

    this.updateDependentFields();
  }

  nameValueChanged(value, previousValue) {
    this.scopeCache = null;

    this.updateDependentFields({ name: previousValue });
    this.updateDependentFields();
  }

  hideValueChanged(value, previousValue) {
    this.updateDependentFields({ hide: previousValue });
    this.updateDependentFields();
  }

  handleChange() {
    this.handleRadioGroupChange();
    this.updateDependentFields();
  }

  handleRadioGroupChange() {
    if (!this.isCheckedRadioInput(this.element)) return;

    const form = this.element.closest('form');
    const name = this.element.getAttribute('name');
    const selector = `input[type="radio"][name="${name}"]`;

    form.querySelectorAll(selector)
      .forEach((radio) => {
        if (radio !== this.element) fire(radio, 'change');
      });
  }

  updateDependentFields({ name: nameValue, hide: hideValue } = {}) {
    const dependentElements = this.getDependentElements(nameValue);

    dependentElements.forEach((el) => {
      const { dependValue, dependInverted } = el.dataset;
      const isActive = this.shouldActivateElement(dependValue);

      this.updateElementState(el, isActive, dependInverted === 'true', hideValue);
    });
  }

  getDependentElements(nameValue) {
    return this.getScopeElement(nameValue).querySelectorAll(`[data-depend='${nameValue || this.nameValue}']`);
  }

  shouldActivateElement(dependValue) {
    if (dependValue) {
      return dependValue === this.element.value;
    }

    return this.element.checked;
  }

  updateElementState(element, isActive, isInverted, hideValue) {
    const finalState = isInverted ? !isActive : isActive;
    const method = finalState ? this.activateElement : this.deactivateElement;

    method.call(this, element, hideValue);
  }

  activateElement(element, hideValue) {
    const shouldDisable = element.dataset.dependDisabled === 'true';

    element.disabled = shouldDisable;

    if (hideValue ?? this.hideValue) {
      show(element, { useHiddenAttr: true });
    }
  }

  deactivateElement(element, hideValue) {
    element.disabled = true;

    if (hideValue ?? this.hideValue) {
      hide(element, { useHiddenAttr: true });
    }
  }

  isCheckedRadioInput(element) {
    return element.tagName.toLowerCase() === 'input'
      && element.getAttribute('type').toLowerCase() === 'radio'
      && element.checked;
  }

  getScopeElement(nameValue) {
    if (nameValue) {
      return this.element.closest(`[data-trigger-scope~="${nameValue}"]`) || document;
    }

    if (!this.scopeCache) {
      this.scopeCache = this.element.closest(`[data-trigger-scope~="${this.nameValue}"]`) || document;
    }

    return this.scopeCache;
  }
}
