import RelativeTime from 'modules/relative_time';

import ExtendedTimeController from './extended_time_controller';

export default class extends ExtendedTimeController {
  static values = {
    ...this.values,
    precise: {
      type: Boolean,
      default: false,
    },
  };

  initialize() {
    super.initialize();

    this.updateIntervalId = null;
  }

  connect() {
    super.connect();
    this.updateElement();
    this.scheduleUpdateElement();
  }

  disconnect() {
    clearInterval(this.updateIntervalId);
    this.updateIntervalId = null;
  }
  
  localeValueChanged(...args) {
    super.localeValueChanged(...args);
    this.updateElement();
    this.scheduleUpdateElement();
  }

  datetimeValueChanged(...args) {
    super.datetimeValueChanged(...args);
    this.updateElement();
    this.scheduleUpdateElement();
  }

  preciseValueChanged() {
    this.scheduleUpdateElement();
  }

  scheduleUpdateElement() {
    clearInterval(this.updateIntervalId);

    const timeoutSeconds = this.preciseValue ? 1 : 60;

    this.updateIntervalId = setInterval(this.updateElement.bind(this), timeoutSeconds * 1000);
  }

  updateElement() {
    this.element.textContent = this.formattedDate || '';
  }

  get formattedDate() {
    if (!this.parsedDatetime) return null;

    return new RelativeTime(this.parsedDatetime, this.localeValue, this.preciseValue).toString();
  }
}
